// body {
//   background-color: red !important;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
// }

// //GLOBAL
// .title {
//   line-height: 34px !important;
//   font-weight: 700 !important;
//   &-dot {
//     position: relative;
//     display: inline-block;
//     margin-left: 0.75rem;
//     &::before {
//       content: "";
//       width: 10px;
//       height: 10px;
//       bottom: 0;
//       background-color: #2267c9;
//       position: absolute;
//     }
//   }
// }

// .custom-text {
//   margin-right: 1rem;
//   display: -webkit-box;
//   -webkit-line-clamp: 2;
//   -webkit-box-orient: vertical;
//   overflow: hidden;
//   text-overflow: ellipsis;
// }

// /* Chrome, Safari, Edge, Opera */
// input::-webkit-outer-spin-button,
// input::-webkit-inner-spin-button {
//   -webkit-appearance: none;
//   margin: 0;
// }

// /* Firefox */
// input[type="number"] {
//   -moz-appearance: textfield;
// }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.mapboxgl-popup {
  font-family: "Arial", sans-serif;
  font-size: 12px;
  color: #ffffff;
  border-radius: 1rem;
  top: 0;
  // box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
}

.mapboxgl-popup-content {
  padding: 10px;
  background-color: #ffffffb8;
  border-color: #bf0000;
  background-color: #bf0000;
  border-width: 1px;
  border-style: solid;
  border-radius: 0.5rem;
}
.mapboxgl-popup-content h3 {
  margin: 0.5rem;
}
.mapboxgl-popup-close-button {
  color: #ffffff;
}

.mapboxgl-popup-close-button:focus-visible {
  border: none;
  outline: none;
}
.mapboxgl-popup-tip {
  border-top-color: #bf0000 !important; /* Uç kısmın rengini ayarlayın */
}

.mapbox-directions-component.mapbox-directions-route-summary {
  color: #000000;
}

.map-arrow {
  position: absolute;
  top: 24px;
  right: 32px;
  z-index: 999;
  cursor: pointer;
}

.mapboxgl-ctrl-top-left {
  background-color: rgb(255 255 255 / 40%);
  height: 100%;
  max-width: 50%;
  // max-height: 70px;
  // overflow: hidden;
  transition: all 0.4s;
  &.active {
    max-height: unset;
    overflow: auto;
    transition: all 0.4s;
  }
}
.mapboxgl-ctrl-directions.mapboxgl-ctrl {
  margin: 0 !important;
  padding: 1rem !important;
}

.mapbox-directions-routes.mapbox-directions-clearfix {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.mapbox-directions-clearfix {
  text-align: center;
  &:not(.mapbox-directions-routes.mapbox-directions-clearfix) {
    border-bottom: 1px solid #aeaeae;
    padding-bottom: 1rem;
  }
}

.mapbox-directions-clearfix input {
  display: none;
}
// .mapbox-directions-clearfix input:checked + label::after {
//   content: "";
//   position: absolute;
//   top: 0;
//   left: -22px;
//   width: 12px;
//   height: 12px;
//   border-radius: 100%;
//   background-color: #800000;
// }
.mapbox-directions-clearfix label {
  position: relative;
  cursor: pointer;
  width: 30px;
  height: 35px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

.mapbox-directions-component-keyline {
  label {
    margin: 0 1rem;
    text-indent: -999999999999999999999px;
  }
}
.mapbox-directions-clearfix {
  &:not(.mapbox-directions-component-keyline) {
    label {
      margin: 0 0.5rem;
      // height: 30px !important;
      line-height: 16px;
      text-align: end;
      &::before {
        top: 10px !important;
      }
    }
  }
  label {
    position: relative;
    &::before {
      content: "";
      font-size: 24px;
      position: absolute;
      top: 5px;
      left: 5px;
      width: 20px;
      height: 20px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url(https://www.svgrepo.com/show/431899/road.svg);
    }
  }
  input {
    &:checked {
      & + label {
        border-bottom: 3px solid #800000;
      }
    }
  }

  // input[id="0"] + {
  //   label {
  //   }
  // }
  // input[id="1"] + {
  //   label {
  //   }
  // }
}

#mapbox-directions-profile-driving + label::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(https://www.svgrepo.com/show/533551/car.svg);
}

#mapbox-directions-profile-walking + label::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(https://www.svgrepo.com/show/308152/walking-person-go-walk-move.svg);
}

#mapbox-directions-profile-cycling + label::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(https://www.svgrepo.com/show/490678/cycling.svg);
}

#mapbox-directions-profile-driving {
  display: none;
}
#mapbox-directions-profile-walking {
  display: none;
}
#mapbox-directions-profile-cycling {
  display: none;
}

#mapbox-directions-profile-driving-traffic + label {
  display: none;
}

#mapbox-directions-profile-driving-traffic {
  display: none;
}
.mapbox-directions-steps {
  padding: 0 !important;
  height: 280px;
  overflow: auto;
}
.mapbox-directions-route {
  &::before {
  }
  &::after {
  }
}
.mapbox-directions-step {
  width: 300px;
  list-style: none;
  border-radius: 1rem;
  padding: 1rem;
  margin-bottom: 1rem;
  color: #000000;
  cursor: pointer;
  background-color: #f1f1f1;
}
.mapbox-directions-step:hover {
  background-color: #e9e9e9;
}

.mapbox-directions-component-keyline:not(.mapbox-directions-profile) {
  display: none;
}

p {
  margin: 0;
}
