/* .swiper {
    height: 320px;
    padding: 2rem;
  }
  
  .swiper-slide {
    display: flex;
    background: #f5e7e7;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
  }
  
  */
  .recent-activities {
    padding: 2.5rem 1rem !important;
  }
  .recent-activities .swiper-button-next, .recent-activities .swiper-button-prev {
    color: #191919;
    top: 50%;
  }

  .recent-activities .swiper-pagination-bullet, .recent-activities .swiper-pagination-bullet {
    background-color: black !important;
    background: black !important;
  }
  .recent-activities .swiper-button-prev {
    left: -10px !important
  }
  .recent-activities .swiper-button-next {
    right: -10px !important
  }