.swiper {
  width: 100%;
  height: 100%;
  padding: 0 32px 0 32px;
  position: relative;
  left: -32px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  object-fit: cover;
}

.swiperWrapper {
  overflow: visible;
  position: relative;
}

.mySwiper {
  overflow: hidden;
  position: relative; /* Bu özellik eklenmelidir */
}

.mySwiper .swiper-button-next,
.mySwiper .swiper-button-prev {
  top: 50%;
  width: 50px;
  height: 50px;
  background-size: 20px 20px;
  color: black;
  transform: translateY(-50%);
  z-index: 10; /* z-index değerini artırıyoruz */
}

.mySwiper .swiper-button-next {
  right: -10px;
}

.mySwiper .swiper-button-prev {
  left: -10px;
}

@media (max-width: 1240px) {
  .swiper {
    padding: 0 1.25rem 0 1.25rem;
    left: 0;
  }
  .mySwiper .swiper-button-next {
    right: -6px;
  }
  .mySwiper .swiper-button-prev {
    left: -6px;
  }

  .mySwiper .swiper-button-next,
  .mySwiper .swiper-button-prev {
    width: 24px;
    height: 24px;
  }
  .mySwiper .swiper-button-next::after,
  .mySwiper .swiper-button-prev::after {
    font-size: 24px;
  }
}
