.small-swiper .swiper-slide-thumb-active {
  opacity: 1 !important;
}

.small-swiper .swiper-slide {
  transition: all 0.4s;
  cursor: pointer;
}

.small-swiper .swiper-slide:hover {
  opacity: 1 !important;
  transition: all 0.4s;
}

.small-swiper iframe,
.big-swiper iframe {
  border-radius: 2rem !important;
}

@media (max-width: 991px) {
  .small-swiper iframe,
  .big-swiper img {
    height: 100% !important;
    object-fit: cover !important;
  }
}

.small-swiper {
  padding: 2rem 0 0 0;
}

@media (max-width: 576px) {
  .small-swiper {
    /* padding: 1rem 1.75rem; */
  }
  .small-swiper .swiper-slide {
    /* min-width: 50%; */
  }
}
