.thumbsSwiper2 {
  height: 400px;
  width: 100%;
}

@media(max-width: 767px) {
  .thumbsSwiper2 {
    height: 200px;
  }
}

.thumbsSwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

@media(max-width: 767px) {
  .thumbsSwiper {
    height: 70px;
  }
}

.thumbsSwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
  transition: opacity 0.4s;
  max-height: 100px;
  overflow: hidden;
  cursor: pointer;
}

.thumbsSwiper .swiper-slide img {
  transform: scale(1);
  transition: transform 0.4s;
}

.thumbsSwiper .swiper-slide:hover {
  transition: opacity 0.4s;
  opacity: 1;
}

.thumbsSwiper .swiper-slide:hover img {
  transform: scale(1.1);
  transition: transform 0.4s;
  opacity: 1;
}

.thumbsSwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.thumbsSwiper2 .swiper-button-next,
.thumbsSwiper2 .swiper-button-prev {
  background-color: #fff;
  transition: background-color 0.4s;
  border-radius: 100%;
  top: calc(50% + 15px);
  width: 30px;
  height: 30px;
  background-size: 20px 20px;
  color: black;
  transform: translateY(-50%);
  z-index: 10; /* z-index değerini artırıyoruz */
}

.thumbsSwiper2 .swiper-button-next:hover,
.thumbsSwiper2 .swiper-button-prev:hover {
  background-color: #f1f1f1;
  transition: background-color 0.4s;
}

.thumbsSwiper2 .swiper-button-next::after,
.thumbsSwiper2 .swiper-button-prev::after {
  font-size: 16px;
}

.thumbsSwiper2 .swiper-button-next::after {
  left: 1px;
  position: relative;
}

.thumbsSwiper2 .swiper-button-prev::after {
  left: -1px;
  position: relative;
}

/* .thumbsSwiper2 .swiper-button-next {
  right: -10px;
}

.thumbsSwiper2 .swiper-button-prev {
  left: -10px;
} */
