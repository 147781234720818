.swiper {
    width: 100%;
    height: 100%;
    left: 0 !important;
    padding: 0;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper .swiper-button-next, .swiper .swiper-button-prev {
    z-index: 999999;
    color: #fff;
    top: calc(50% + 25px);
  }

  @media(max-width: 991px) {
    .swiper .swiper-button-next {
      right: 0
  
    }
  
    .swiper .swiper-button-prev {
     left: 0
  
    }
  }


  .swiper .swiper-button-next::after, .swiper .swiper-button-prev::after {
  font-size: 1.5rem;
  }
  .swiper .swiper-pagination-bullet, .swiper .swiper-pagination-bullet-active {
    background: #ffffff !important;
  }